<template>
  <div>
    <div class="row">
      <div class="col-lg-5">
        <content-loader v-if="!detailsLoaded" :height="200" class="loader-bar">
          <rect height="100%" width="100%" />
        </content-loader>
        <div v-else class="card-wrapper">
          <div class="metric-card">
            <div class="title-container">
              <h2 class="title">{{ score.name }}</h2>
            </div>
            <p class="description">
              {{ score.longDescription }}
            </p>
            <p class="value"
              >{{ score.value }}<span class="unit">{{ score.unit }}</span></p
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <content-loader v-if="!measuresLoaded" :height="450" class="chart-loader">
          <rect height="100%" width="100%" />
        </content-loader>
        <div v-else class="card-wrapper">
          <div class="chart-card">
            <div class="title-container">
              <h2 class="title">Nutzer vs. Durchschnitt</h2>
            </div>
            <div class="chart-wrapper">
              <radar-chart class="chart" :chart-data="radarChartData" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5">
        <content-loader v-if="!detailsLoaded" :height="450" class="chart-loader">
          <rect height="100%" width="100%" />
        </content-loader>
        <div v-else class="card-wrapper">
          <div class="chart-card">
            <div class="title-container">
              <h2 class="title">Verteilung des Gesamtscores</h2>
            </div>
            <div class="chart-wrapper">
              <bar-chart :chart-data="barChartData" :highlight="barChartHighlight" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/report-base/charts/BarChart";
import RadarChart from "@/components/report-base/charts/RadarChart";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    BarChart,
    RadarChart,
    ContentLoader,
  },
  props: {
    reportDetails: {
      type: Object,
      default: () => ({}),
    },
    measures: {
      type: Object,
      default: () => ({}),
    },
    measuresLoaded: {
      type: Boolean,
      default: false,
    },
    detailsLoaded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    score() {
      return {
        name: "Gesamtscore",
        longDescription: "Der berechnete Gesamtscore von EntwicklerHeld. Je höher, desto besser!",
        unit: "/300",
        value: this.reportDetails.score,
      };
    },
    barChartData() {
      return this.reportDetails.scoreDistribution;
    },
    barChartHighlight() {
      let index = this.reportDetails.scoreDistribution.usersRegionIndex;
      let bgArr = [];
      for (var i = 0; i < 9; i++) bgArr.push("rgba(80, 151, 151, 0.5)");
      bgArr.splice(index, 0, "#509797");
      return bgArr;
    },
    radarChartData() {
      return {
        measures: this.measures,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
}

.chart-container {
  background-color: white;
  box-shadow: $elevation1;
  border-radius: 5px;
  margin-bottom: 2rem;
}

.card-wrapper {
  margin-bottom: 2rem;
  display: flex;
}

.chart-wrapper {
  max-width: 100%;
}

.chart-card {
  padding: 2rem 2rem;
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: $elevation1;

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .title {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 0;
      height: 15%;
      color: $eh-green;
    }
  }
}

.metric-card {
  padding: 2rem 2rem;
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: $elevation1;

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .title {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 0;
      height: 15%;
      color: $eh-green;
    }
  }

  .description {
    font-size: 1rem;
    color: #aaa;
  }

  .unit {
    margin-left: 10px;
    font-size: 1rem;
  }

  .value {
    font-weight: bold;
    font-size: 3rem;
    margin: 0;
  }
}

.information-loader {
  margin-bottom: 2rem;
}
</style>

<template>
  <div>
    <c-lead-report :accepted-challenge-uuid="acceptedChallengeUuid" />
  </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import CLeadReport from "@/components/report-by-acceptance/CLeadReport";

export default {
  components: { CLeadReport },
  mixins: [AuthenticatedMixin],
  props: {
    acceptedChallengeUuid: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="report">
    <c-header :accepted-challenge-uuid="acceptedChallengeUuid" />
    <div class="row">
      <div class="d-flex report-tab-wrapper col-lg-12">
        <div class="report-tab" :class="{ active: selectedTab === 'SCORE' }" @click="selectedTab = 'SCORE'">
          Score
        </div>
        <div class="report-tab" :class="{ active: selectedTab === 'METRICS' }" @click="selectedTab = 'METRICS'">
          Metriken
        </div>
        <div class="report-tab" :class="{ active: selectedTab === 'CODE' }" @click="selectedTab = 'CODE'">
          Code Review
        </div>
      </div>
    </div>
    <div v-if="reportDetails.error && selectedTab === 'SCORE'">
      <p>
        Ein Score ist <strong>noch nicht verfügbar.</strong><br/>
        Ausführliche Werte finden Sie hier, sobald die <strong>Analyse der Lösung abgeschlossen</strong> ist.
      </p>
    </div>
    <p-score
      v-if="!reportDetails.error && selectedTab === 'SCORE'"
      :measures="measures.apiData"
      :report-details="reportDetails.apiData"
      :measures-loaded="measures.loaded"
      :details-loaded="reportDetails.loaded"
    />
    <p-metrics v-if="selectedTab === 'METRICS' && measures.loaded" :measures="measures.apiData" />
    <c-code v-if="selectedTab === 'CODE'" :accepted-challenge-uuid="acceptedChallengeUuid" />
  </div>
</template>

<script>
import CHeader from "@/components/report-by-acceptance/CHeader";
import PMetrics from "@/components/report-base/PMetrics";
import PScore from "@/components/report-by-acceptance/PScore";
import CCode from "@/components/report-by-acceptance/CCode";
import { QualityReport, Scores } from "@/services/quality/QualityServiceResponses";
import { services } from "@/main";
import { eventBus } from "@/eventbus";
import { Measures } from "../../services/quality/QualityServiceResponses";

export default {
  components: {
    CHeader,
    PMetrics,
    PScore,
    CCode,
  },
  props: {
    acceptedChallengeUuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedTab: "SCORE",
      reportDetails: new QualityReport(),
      measures: new Measures(),
    };
  },
  watch: {},
  mounted() {
    this.reportDetails = services.qualityService.getQualityReportByUuid(this.acceptedChallengeUuid);
    this.measures = services.qualityService.getMeasuresByUuid(this.acceptedChallengeUuid);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.report {
  margin-left: 1rem;
}
.report-tab-row {
  margin-left: 0;
}

.report-tab-wrapper {
  margin-bottom: 2rem;
}

.report-tab {
  background-color: white;
  padding: 1rem 2rem;
  margin-right: 2rem;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(.active) {
    background-color: $eh-20;
  }
}

.active {
  background-color: $eh-green;
  color: white;
}
</style>

<template>
  <h1 v-if="loaded && !error">
    Bewertung
    <span class="heading-appendix">{{ challengeTitle }}</span>
  </h1>
  <content-loader v-else :height="15" class="loader-bar">
    <rect height="100%" width="60%" />
  </content-loader>
</template>

<script>
import { AcceptedChallengeDetails } from "@/services/task-management/TaskManagementServiceResponses";
import { services } from "@/main";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ContentLoader,
  },
  props: {
    acceptedChallengeUuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      acceptedChallengeRequest: new AcceptedChallengeDetails(),
    };
  },
  computed: {
    loaded() {
      return this.acceptedChallengeRequest.loaded;
    },
    error() {
      return this.acceptedChallengeRequest.error;
    },
    challengeTitle() {
      return this.acceptedChallengeRequest.apiData.title;
    },
  },
  mounted() {
    this.acceptedChallengeRequest = services.taskManagementService.getAcceptedChallengeDetails(
      this.acceptedChallengeUuid
    );
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 4rem;
}
.heading-appendix {
  font-size: 1.2rem;
  color: #777;
}

.loader-bar {
  margin-bottom: 2rem;
}
</style>
